label {
  display: block;
  margin: 1em;
}

li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 2em;
  margin: 0.2em;
  border: 1px solid silver;
}

.card {
  box-shadow: 0 0 0.2em rgba(0, 0, 0, 20%);
}

.possibleTransfer {
  background-color: hsla(100, 0%, 100%, 70%);
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 50%);
}

.transfer {
  background-color: hsl(100, 100%, 80%, 70%);
  border: 1px solid black;
  box-shadow: 0.2em 0.2em 0.3em 0.1em rgba(0, 0, 0, 50%);
  position: relative;
  left: -0.1em;
  top: -0.1em;
}

textarea {
  width: 100%;
  height: 5em;
}