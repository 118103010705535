* {
  font-family: 'Questrial', sans-serif;
}

button {
  margin: 1em;
}

.top {
  display: flex;
}

.top > * {
  flex: 1 0;
}

.collectorPane {
  border: 1px solid silver;
}

.collectorPane1 {
  background-color: hsl(90 30% 90%);
}

.collectorPane2 {
  background-color: hsl(210 30% 90%);
}